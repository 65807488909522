import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './ContactBar.css';
import { useNavigate } from 'react-router-dom';

function ContactBar() {

const navigate = useNavigate(); // Hook for navigation

  const handleContactClick = () => {
    navigate('/contact-us'); // Redirect to the home page path
  };
  
  return (
    <>
        <Card >
            <Card.Body>
                <Container className='cont'>
                    <Row>
                        <Col xs={12} sm={9} md={9}>
                            <h2>GET MORE INFO ON OUR SOLUTIONS.</h2>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                            <br/>
                            <Button variant="outline-light" size="lg" onClick={handleContactClick}>
                                CONTACT US
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </>
  )
}

export default ContactBar