import React from 'react';
import Button from 'react-bootstrap/Button';

import { Navbar, Container, Nav  } from 'react-bootstrap';

const BrandImg = require('../asserts/images/FMS_LOGO_NB.png');

function TopNavbar() {

  const handleClick = () => {
    window.location.href = 'https://portal.fraudmitigationsolutions.co.za/'; 
  }
  return (
    <>
      <Navbar bg='dark' className='navbar' variant='dark' expand='lg'>
        <Container>
          <Navbar.Brand href='/' >
          <img
              src={BrandImg}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="FMS"
            />
            FMS
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id="basic-navbar-nav" className="me-auto justify-content-end">
            <Nav >
              <Nav.Link href="#Solutions">Solutions</Nav.Link>
              <Nav.Link href="/about-us">About</Nav.Link>
              <Nav.Link href="/contact-us">Contact</Nav.Link>
              <Nav.Link href="/faq">FAQ</Nav.Link>
              <Button variant="outline-light" onClick={handleClick} >
                  LOGIN
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default TopNavbar