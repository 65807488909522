import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ContactComponent() {

    const handleContactClick = () => {
       alert("Contact Submitted");
    };
  return (
    <>
        <Form>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <Col>
                        <Form.Control placeholder="Name" />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Email" />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Phone No" />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Company Name" />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Enquiry" />
                        </Col>
                        <Col>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control placeholder="Comments"  as="textarea" rows={3} />
                            </Form.Group> 
                        </Col>
                        <Col>
                            <Button variant="outline-warning" type="submit" onClick={handleContactClick}>
                                SEND MESSAGE
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                    
                    </Col>
                </Row>
            </Container>   
        </Form>
    </>
  )
}

export default ContactComponent