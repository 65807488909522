import React from 'react';
import './Home.css';
import CarouselBar from '../components/Carousel';
import CardSwap from '../components/CardSwap';
import CardComponent from '../components/Card';
import Value from '../components/value';

function Soon() {

  const Cube1 = require('../asserts/images/API.jpg');
  const Cube2 = require('../asserts/images/laptop.jpg');
  const Cube3 = require('../asserts/images/FMS_LOGO.png');

  const c1 = 'Powerful Verification. Trusted Results';
  const t1 = 'Our in-depth verification process provides you with the most accurate and reliable information, allowing you to make informed decisions with confidence';
  const c2 = 'Empowering Businesses with Confidence';
  const t2 = 'FMS understands the importance of a secure and trustworthy work environment.  By partnering with us, you gain access to a comprehensive suite of background.';
  const c3 = 'Secure Your Workforce';
  const t3 = 'With FMS, you can safeguard your company and employees.  Our background checks help deter fraud, identify potential risks, and create a safer work environment for everyone';

  const working1 = require('../asserts/images/carouselPictures/Caro1.png');
  const working2 = require('../asserts/images/carouselPictures/Caro2.png');
  const working3 = require('../asserts/images/carouselPictures/working3.jpg');

  const text1 = 'Effortlessly streamline your onboarding and vetting processes while prioritizing security.  Fraud Mitigation Solutions (FMS) empowers you to achieve both.  Our seamless integration options allow you to leverage our comprehensive background checks directly within your existing workflows.  This eliminates manual data entry and expedites the onboarding process'
  const text2 = 'By partnering with FMS, you can mitigate risks, ensure regulatory compliance, while experiencing a smooth and efficient integration process..';
 
  const textt1 = 'Experience the power of background checks with unmatched ease.  Our user-friendly application provides a centralized hub for managing all your vetting needs.  The intuitive interface allows you to initiate background checks with a few clicks,  track their progress in real-time, and review results in a clear and organized manner.';
  const textt2 = 'Simplify your background check workflows and gain valuable insights  – all within a user-friendly and secure application environment.';
  
  const title1 =  'Secure Your Workforce - Seamless Integration';
  const title2 =  'User-Friendly Application';

  const hading1 = 'Accelerate Your Onboarding Process';
  const hading2 = 'Navigate FICA Compliance with Confidence';
  const hading3 = 'Proactive Mitigation';

  const hadingText1 = 'FMS understands this need and offers solutions to accelerate your onboarding process.  Through our API integration, background checks can be seamlessly initiated within your existing HR platform. This eliminates the need for manual data entry and streamlines the entire process,  allowing you to onboard new hires faster and with minimal disruption.  Additionally, our user-friendly application provides a centralized hub for managing background checks, further enhancing efficiency throughout the onboarding journey.';
  const hadingText2 = 'The Financial Intelligence Centre Act (FICA) plays a crucial role in combating financial crime and money laundering.  For businesses in the financial sector, adhering to FICA regulations is mandatory.  FMS can be your trusted partner in navigating FICA compliance.  Our comprehensive suite of verification services helps you fulfill all FICA requirements with confidence. By partnering with FMS, you can demonstrate due diligence and mitigate potential legal risks associated with non-compliance';
  const hadingText3 = 'Mitigating risk should be a cornerstone of any successful business strategy.  At FMS, we empower you with the tools to proactively mitigate potential threats and safeguard your organization.';

  return (
    <>
      <CarouselBar caption1={c1} text1={t1} image1={working1} caption2={c2} text2={t2} image2={working2} caption3={c3} text3={t3} image3={working3}/>
      <Value text1={hadingText1} hading1={hading1} text2={hadingText2} hading2={hading2} text3={hadingText3} hading3={hading3} imageUrl={Cube3}/>
      <CardSwap title={title1} text1={text1} text2={text2} imageUrl={Cube1} />
      <CardComponent title={title2} text1={textt1} text2={textt2} imageUrl={Cube2} />
    </>
  );
}

export default Soon;