import Carousel from 'react-bootstrap/Carousel';

function CarouselBar({caption1, text1, image1, caption2, text2, image2, caption3, text3, image3}) {
  return (
    <Carousel >
      <Carousel.Item style={{ backgroundImage: `url(${image1})`, backgroundSize: 'cover' }}>
        <h1>{caption1}</h1>
        <p>{text1}</p>
      </Carousel.Item>
      <Carousel.Item style={{ backgroundImage: `url(${image2})`, backgroundSize: 'cover' }}>
        <h1>{caption2}</h1>
        <p>{text2}</p>
      </Carousel.Item>
      <Carousel.Item style={{ backgroundImage: `url(${image3})`, backgroundSize: 'cover' }}>
        <h1>{caption3}</h1>
        <p>{text3}</p>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBar;

