import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Content({ title, text1, text2, imageUrl }) {
  return (
    <>  
        <Card >
            <Card.Body>
                <Container>
                    <Row className="d-flex justify-content-center">
                    <Card.Title>{title}</Card.Title>
                    </Row>

                    <Row>
                    <Col xs={12} sm={6} md={4}>
                        <Card.Img variant="top" src={imageUrl} />
                    </Col>
                    <Col xs={12} sm={6} md={8}>
                        <Card.Text>
                                {text1}
                        </Card.Text>
                        <Card.Text>
                            {text2}
                       </Card.Text>
                    </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </>
    
  );
}

export default Content;