import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function value({ text1, hading1 , text2 , hading2 , text3, hading3, imageUrl }) {
  return (
    <>
       <Card >
            <Card.Body>
                <Container>
                    <Row className='valueCard'>
                        <Col xs={12} sm={8} md={8}>
                           <Row className='valueCard'>
                                <Col xs={1} sm={1} md={1}>
                                </Col>
                                <Col xs={11} sm={11} md={11} >
                                    <Row><h3>{hading1}</h3></Row>
                                    <Row><p>{text1}</p></Row>
                                </Col>
                           </Row>
                           <Row>
                                <Col xs={1} sm={1} md={1}>
                                    <i class="bi bi-check-circle-fill"></i>
                                </Col>
                                <Col xs={11} sm={11} md={11}>
                                    <Row><h3>{hading2}</h3></Row>
                                    <Row><p>{text2}</p></Row>
                                </Col>
                           </Row>
                           <Row>
                                <Col xs={1} sm={1} md={1}>
                                    <i class="bi bi-check-circle-fill"></i>
                                </Col>
                                <Col xs={11} sm={11} md={11}>
                                    <Row><h3>{hading3}</h3></Row>
                                    <Row><p>{text3}</p></Row>
                                </Col>
                           </Row>
                        </Col>
                        <Col xs={12} sm={4} md={4}>
                            <Card.Img variant="bottom" className='centerCon' src={imageUrl} />
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </>
  )
}

export default value