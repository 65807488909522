import React from 'react';
import CarouselBar from '../components/Carousel';
import CardComponent from '../components/Card';
import CardSwap from '../components/CardSwap';
import ContactBar from '../components/ContactBar';
import Value from '../components/value';
import '../styling/main.css';


function aboutUs() {
  const text1 = 'Fraud Mitigation Solutions (FMS) was founded with the mission to empower businesses with comprehensive employee verification solutions. We recognized the growing need for robust background checks to mitigate fraud and ensure a secure work environment.';
  const text2 = 'We are a 100% Black-owned company, bringing a unique and multifaceted perspective to the industry. This diversity of thought fuels innovation and allows us to deliver exceptional service to a wider range of clients.';
  const text3 = ' We continuously explore new technologies and methodologies to enhance the efficiency and effectiveness of our verification processes, staying ahead of evolving threats.';
  const working1 = require('../asserts/images/carouselPictures/Caro1.png');
  const working2 = require('../asserts/images/carouselPictures/Caro2.png');
  const working3 = require('../asserts/images/carouselPictures/working3.jpg');
  
  const Cube1 = require('../asserts/images/carouselPictures/Cube1.png');
  const Cube2 = require('../asserts/images/carouselPictures/Cube2.png');
  const Cube3 = require('../asserts/images/carouselPictures/Cube3.png');
  
  const c1 = 'About Fraud Mitigation Solutions';
  const t1 = 'Education credentials (Matric, Tertiary & More), and Drivers licenses, conducted with the utmost integrity.';
  const c2 = 'About Protect your Business';
  const t2 = 'Utilizing innovative methods to confirm applicant identities while maintaining strict data security and privacy.';
  const c3 = 'About Comprehensive Vetting';
  const t3 = 'Accountable screening for criminal offenses on national databases, adhering to all legal regulations.';

  const tt1 = 'We take the time to understand your specific needs and customize our services to ensure the best possible outcomes.'
  const tt2 = 'Our flexible approach ensures you receive the necessary level of verification, regardless of your company size or industry.';

  const hading1 = 'Integrity';
  const hading2 = 'Accountability';
  const hading3 = 'Innovation';

  const textSwap1 = 'Streamlined Onboarding: FMS\'s solutions can expedite the onboarding process by automating tasks and minimizing manual data entry. This reduces administrative costs and allows your new hires to become productive members of your team faster.'
  const textSwap2 = 'Minimize Fraudulent Activity: Thorough background checks help deter fraud by verifying identities and credentials. This can save your business significant financial losses associated with fraudulent transactions or employee theft.';
  return (
    <>
      <CarouselBar caption1={c1} text1={t1} image1={working1} caption2={c2} text2={t2} image2={working2} caption3={c3} text3={t3} image3={working3}/>
      <CardComponent text1={text1 + " " + text2} text2={tt1 + " " + tt2} imageUrl={Cube2} />
      
      <Value text1={text1} hading1={hading1} text2={text2} hading2={hading2} text3={text3} hading3={hading3} imageUrl={Cube1}/>
      <ContactBar />
      <CardSwap title={""} text1={textSwap1} text2={textSwap2} imageUrl={Cube3} />
    </>
  );
}

export default aboutUs;