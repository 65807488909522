import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';

function AccordionComponent({q0, a0, q1, a1, q2, a2, q3, a3, q4, a4, q5, a5, q6, a6, q7, a7}) {
  return (
    <>
      <Container>
        <Accordion >
              <Accordion.Item eventKey="0">
                  <Accordion.Header>{q0}</Accordion.Header>
                  <Accordion.Body>
                  {a0}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                  <Accordion.Header>{q1}</Accordion.Header>
                  <Accordion.Body>
                  {a1}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                  <Accordion.Header>{q2}</Accordion.Header>
                  <Accordion.Body>
                  {a2}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                  <Accordion.Header>{q3}</Accordion.Header>
                  <Accordion.Body>
                  {a3}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                  <Accordion.Header>{q4}</Accordion.Header>
                  <Accordion.Body>
                  {a4}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                  <Accordion.Header>{q5}</Accordion.Header>
                  <Accordion.Body>
                  {a5}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                  <Accordion.Header>{q6}</Accordion.Header>
                  <Accordion.Body>
                  {a6}
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                  <Accordion.Header>{q7}</Accordion.Header>
                  <Accordion.Body>
                  {a7}
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>
      </Container>
    </>
  )
}

export default AccordionComponent